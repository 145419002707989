<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header
                    :title="$t('merge_section')"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>

            <template v-slot:header-mobile>
                <HeaderMobile
                    :title="$t('merge_section')"
                    :isColumns="true"
                    @filter-div-status="datatable.filterStatus=$event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>

                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear">
                <b-row>
                    <b-col sm="4">
                        <b-form-group :label="$t('course')">
                            <course-auto-complete v-model="datatable.queryParams.filter.course_id"/>
                        </b-form-group>
                    </b-col>
                  <b-col sm="4">
                    <ValidationProvider name="semester_id" v-slot="{ errors }">
                      <b-form-group :label="$t('semester')">
                        <semesters-selectbox v-model="datatable.queryParams.filter.semester_id">
                        </semesters-selectbox>
                        <b-form-invalid-feedback v-for="(err, index) in errors" :key="index"> {{ err }} </b-form-invalid-feedback>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
            </datatable-filter>
            <b-row class="align-items-center my-4 ml-1" v-show="datatable.showTable">
                <b-col sm="2" md="1" lg=1 class="mr-1 p-0">
                    <b-form-checkbox
                        id="teams"
                        v-model="teams"
                        name="checkbox-1"
                        :value="1"
                        :unchecked-value="0"
                    >
                        Teams
                    </b-form-checkbox>
                </b-col>

                <b-col sm="2" md="1" class="p-0 ml-md-2">
                    <b-form-checkbox
                        id="lms"
                        v-model="lms"
                        name="checkbox-1"
                        :value="1"
                        :unchecked-value="0"
                    >
                        Lms
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-per-page-change="onPerPageChange"
                       :title="$t('joining_place')"
                       v-show="datatable.showTable"
            >
            </datatable>

            <datatable class="my-3"
                       :isLoading.sync="datatableMerge.isLoading"
                       :columns="datatableMerge.columns"
                       :rows="datatableMerge.rows"
                       :total="datatableMerge.total"
                       :queryParams="datatableMerge.queryParams"
                       @on-page-change="onPageChangeMerge"
                       @on-sort-change="onSortChangeMerge"
                       @on-per-page-change="onPerPageChangeMerge"
                       :title="$t('place_added')"
                       v-show="datatableMerge.showTable"
            >
            </datatable>
            <b-col cols="6" md="12" class="my-2 p-0">
                <b-button variant="primary" @click="saveCollection">
                   {{$t('save')}}
                </b-button>
            </b-col>
            <CommonModal ref="createFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('add').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form v-if="formProcess=='create'"
                                 :addValueObject="{for_teams:teams,for_lms:lms,section_ids:JSON.stringify(Ids)}"
                                 @createFormSuccess="createFormSuccess"/>
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpperCase() }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"
                                 :lms="lms" :teams="teams"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";

// Services
import CourseMergeSection from "@/services/CourseMergeSections";
import SectionService from "@/services/SectionService"

import qs from 'qs'

// Page
import UpdateForm from "./UpdateForm";
import CreateForm from "./CreateForm";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import CourseAutoComplete from "@/components/interactive-fields/CourseAutoComplete";

export default {
    components: {
        CourseAutoComplete,
        HeaderMobile,
        Header,
        AppLayout,

        DatatableFilter,
        Datatable,
        CommonModal,

        UpdateForm,
        CreateForm
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('add'),
                                class: 'ri-add-line align-middle top-minus-1 mr-3 text-muted',
                                callback: (row) => {
                                    this.addSection(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('course_code'),
                        field: 'course_code',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('course_name'),
                        field: ('course_name'),
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('section'),
                        field: 'section',
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 20
                }
            },
            datatableMerge: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "mergesection_delete",
                                callback: (row) => {
                                    this.formDelete(row.code, row.id)
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'mergesection_update',
                                callback: (row) => {
                                    if (this.throwError()) return;
                                    this.loadData(row.id);
                                },
                                show: (row) => {
                                    return this.extractCollection(row)
                                }
                            }
                        ]
                    },
                    {
                        label: this.toUpperCase('course_code'),
                        field: 'code',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('course_name'),
                        field: 'name',
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase('section_id'),
                        field: 'section_ids',
                        hidden: false,
                    },
                ],
                rows: [],
                total: 0,
                showTable: false,
                queryParams: {
                    filter: {},
                    page: 1,
                    limit: 20
                }
            },
            collection: [],
            Ids: [],
            teams: 0,
            lms: 0,
            formProcess: null,
            formId: null
        }
    },
    methods: {
        createFormShow() {
            this.formId = null
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        filterSet() {
            this.datatable.queryParams.filter = {
                course_code: null,
                course_name: null,
                department_code: null,
            }
            this.getRows()
        },
        filterClear() {
            this.filterSet()
            this.getRows();
        },
        filter() {
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChangeMerge(params) {
            this.datatableMerge.queryParams.page = params.currentPage;
            this.getRowsMergeCourses();
        },
        onPerPageChangeMerge(params) {
            this.datatableMerge.queryParams.limit = params.currentPerPage;
            this.datatableMerge.queryParams.page = 1;
            this.getRowsMergeCourses();
        },
        onSortChangeMerge(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatableMerge.queryParams.sort = sortType + params[0].field
            this.getRowsMergeCourses();
        },
        addSection(row) {
            if (!this.checkPermission('mergesection_store')) return
            if (!this.checkTeamAndLms()) {
                return
            }
            this.datatableMerge.isLoading = true;

            if (this.checkSameSectionId(row.id)) {
                this.$toast.error(this.$t(this.$t('already_available')));
                this.datatableMerge.isLoading = false;
                return
            }
            this.addCollection(row)
            this.datatableMerge.isLoading = false;
        },
        checkTeamAndLms() {
            if (this.teams == false && this.lms == false) {
                this.$toast.error(this.$t(`${this.teams ? '' : 'Teams'}${this.teams == this.lms ? ' ' + this.$t('or') : ''} ${this.lms ? '' : 'Lms'} ${this.$t('not_selected')}!`));
                return false
            }
            return true
        },
        checkSameSectionId(id) {
            return this.collection.some(section => section.id === id)
        },
        checkCollectionLength(data=[]){
            if(data.length > 4) return false
            return true
        },
        addCollection(section) {
            if(!this.checkCollectionLength(this.collection)){
                this.$toast.error(this.$t('you_can_combine_up_to_five_lessons'))
                return
            }
            let data = {
                code: section.course_code,
                name: section[("course_name")],
                for_teams: this.teams,
                for_lms: this.lms,
                class_id: section.class_id,
                semester_id: section.semester_id,
                section_ids: section.section,
                id: section.id
            }
            this.collection.push(data);
            this.datatableMerge.rows.push(data);
        },
        getRowsMergeCourses() {
            this.datatableMerge.showTable = true;
            this.datatableMerge.isLoading = true;

            return CourseMergeSection.get()
                .then((response) => {
                    let data = response.data.data
                    let configData = data.map(course=>{
                        return {...course,section_ids:JSON.parse(course.section_ids).join(",")}
                    })
                    this.datatableMerge.rows = [...configData,...this.collection]
                    this.datatableMerge.total = response.data.pagination.total
                }).catch((err) => this.showErrors(err))
                .finally(() => {
                    this.datatableMerge.isLoading = false;
                });
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatableMerge.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return SectionService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total
                }).catch((err) => this.showErrors(err))
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        saveCollection() {
            if (this.throwError()) return
            if (this.collection == null || this.collection.length == 0) {
                this.$toast.error(this.$t("no_lessons_to_be_added"));
                return false
            }
            if (this.formProcess !== 'create') this.collectIds();
            this.formProcess = 'create';
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        throwError() {
            if (!this.checkTeamAndLms()) return true
        },
        collectIds() {
            this.collection.forEach(section => {
                this.Ids = this.Ids.concat([section.id])
            })
        },
        collectionDelete(course_code) {
            let firstLength = this.collection.length;
            if (firstLength === 0) return false
            this.collection = this.collection.filter(section => section.code !== course_code);
            let check = firstLength > this.collection.length;
            if (check) {
                this.$toast.success(this.$t("merge_section_deleted"));
                return check
            }
        },
        formDelete(course_code, id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        if (this.collectionDelete(course_code)) return this.getRowsMergeCourses();
                        CourseMergeSection.del(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRowsMergeCourses();
                            })
                            .catch(error => {
                                this.showErrors(error)
                            });
                    }
                })
        },
        loadData(id) {
            this.formProcess = "update";
            this.formId = id;
            this.$refs.updateFormModal.$refs.commonModal.show();
        },
        formClear() {
            this.formId = null;
            this.formProcess = null;
            this.Ids = [];
        },
        showForm(id) {
            this.formId = id
            this.formProcess = 'show'
            this.$refs.showFormModal.$refs.commonModal.show()
        },
        createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRowsMergeCourses()
            this.formClear();
            this.collection = [];
            this.Ids = [];
        },
        updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRowsMergeCourses()
            this.formClear();
        },
        extractCollection(row) {
            return !this.collection.some(x => x.id == row.id)
        },
    }
}
</script>
